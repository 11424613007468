
<template>
    <div id="order-list-table">
        <vx-card ref="filterCard" title="Filter orders by status" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-3">
                        <ul class="leftx sm:flex items-center">
                            <li class="mr-10">
                                <vs-radio v-model="defaultStatus" vs-value="draft">Draft</vs-radio>
                            </li>
                            <li class="mr-10">
                                <vs-radio color="#0dcaf0" v-model="defaultStatus" vs-value="pending">Pending</vs-radio>
                            </li>
                            <li class="mr-10">
                                <vs-radio color="warning" v-model="defaultStatus" vs-value="delivered">Delivered</vs-radio>
                            </li>
                            <li class="mr-10">
                                <vs-radio color="success" v-model="defaultStatus" vs-value="completed">Completed</vs-radio>
                            </li>
                            <li class="mr-10">
                                <vs-radio color="#c62828" v-model="defaultStatus" vs-value="refunded">Refunded</vs-radio>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </vx-card>

        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <!-- ITEMS PER PAGE -->
                <div class="flex-grow">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                    <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ orders.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : orders.length }} of {{ orders.length }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                        <span>50</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                        <span>100</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(200)">
                        <span>200</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(500)">
                        <span>500</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(1000)">
                        <span>1000</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(2000)">
                        <span>2000</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="gridApi.paginationSetPageSize(5000)">
                        <span>5000</span>
                    </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                </div>

                <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
                <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item  style="min-width:160px;" @click="markStarted()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Start&nbsp;Order</span>
                          </span>
                      </vs-dropdown-item>
                       <vs-dropdown-item  style="min-width:160px;" @click="startCampaign()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Start&nbsp;Campaign</span>
                          </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="assignWorker()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Assign&nbsp;Worker</span>
                          </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateStatus()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Update&nbsp;Status</span>
                          </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="confirmMarkAsPaid()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Pay&nbsp;Workers</span>
                          </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="confirmMassDelete()">
                          <span class="flex items-center">
                          <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                          <span>Delete</span>
                          </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>

            <!-- AgGrid Table -->
            <ag-grid-vue
                ref="agGridTable"
                :components="components"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 text-sm ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="orders"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :floatingFilter="true"
                domLayout='autoHeight'
                :pagination="true"
                :paginationPageSize="paginationPageSize"
                :suppressPaginationPanel="true"
                :enableRtl="$vs.rtl">
            </ag-grid-vue>

            <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
        <vs-popup class="holamundo" fullscreen @close="close"
          title="Email Campaign" 
          :active.sync="campaignPopup">
             <campaign-mail v-if="campaignPopup" 
              :selected="selectedData"
              @close="initializeAll()"/>
        </vs-popup>
        <vs-popup class="holamundo" @close="close"
          title="Assign order(s) to worker(s)" 
          :active.sync="assignPopup">
             <assign-worker v-if="assignPopup" 
              :selected="selectedIds"
              @sendRequest="resquestProcessor($event)"/>
        </vs-popup>
        <vs-popup class="holamundo" @close="close" title="Update order status" :active.sync="updateStatusPopup">
            <update-status v-if="updateStatusPopup"
            :selected="selectedIds"
            @sendRequest="resquestProcessor($event)"/>
        </vs-popup>
    </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
// Cell Renderer
import CellRendererLink from "./cell-renderer/CellRendererLink.vue"
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererAmount from "./cell-renderer/CellRendererAmount.vue"
import CellRendererDate from "./cell-renderer/CellRendererDate.vue"
import CellRendererOrderLink from "./cell-renderer/CellRendererOrderLink.vue"
import CellRendererStarted from "./cell-renderer/CellRendererStarted.vue"
import CellRendererWorkers from "./cell-renderer/CellRendererWorkers.vue"

import UpdateStatus from "./UpdateStatus.vue"
import AssignWorker from "./AssignWorker.vue"
import CampaignMail from "./CampaignMail.vue"

export default {
  name:"order-list-table",
  props:{
    orderList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UpdateStatus,
    AssignWorker,
    CampaignMail,
    AgGridVue,
    // Cell Renderer
    CellRendererLink,
    CellRendererActions,
    CellRendererAmount,
    CellRendererDate,
    CellRendererOrderLink,
    CellRendererWorkers,
    CellRendererStarted,
  },
  data() {
    return {
      defaultStatus: "pending",
      searchQuery: "",
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          width: 150,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
          cellRendererFramework: 'CellRendererOrderLink',
        },
        {
          headerName: 'Buyer',
          field: 'buyer.first_name',
          filter: true,
          minWidth: 200,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Workers',
          field: 'workers',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererWorkers',
        },
        {
          headerName: 'Date',
          field: 'created_at',
          filter: true,
          width: 150,
          cellRendererFramework: 'CellRendererDate',
        },
        {
          headerName: 'Amount',
          field: 'paid_amount',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
          width: 100,
        },
        {
          headerName: 'Started',
          field: 'is_started',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererStarted',
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],
      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
        CellRendererAmount,
        CellRendererDate,
        CellRendererOrderLink,
        CellRendererStarted,
        CellRendererWorkers,
      },
      selectedData: [],

      assignPopup: false,
      updateStatusPopup: false,
      campaignPopup: false
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus"
  },
  computed: {
    orders() {
      return this.orderList.filter((order) => (order.status == this.defaultStatus))
    },
    paginationPageSize() {
      if(this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages() {
      if(this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    selectedIds(){
      return [...this.selectedData.map(node => node.id)];
    }
  },
  methods: {
    refreshQueryStatus(){
        if(this.$route.query.status){
            this.defaultStatus = this.$route.query.status
        }
    },
    getSelectedRows(){
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.selectedData = selectedNodes.map(node => node.data);
    },
    startCampaign(){
      this.getSelectedRows();
      if(this.selectedData.length < 1){ this.alertError("You have not selected any item"); return}
      this.campaignPopup = true
    },
    updateStatus(){
      this.getSelectedRows();
      if(this.selectedData.length < 1){ this.alertError("You have not selected any item"); return}
      this.updateStatusPopup = true
    },
    assignWorker(){
      this.getSelectedRows();
      if(this.selectedData.length < 1){ this.alertError("You have not selected any item"); return}
      this.assignPopup = true;
    },
    confirmMassDelete(){
      this.getSelectedRows();
      let items = this.selectedData.length;
      if(items < 1){ this.alertError("You have not selected any item"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: `You are about to delete ${items} order${(items > 1)? 's':'' }`,
        accept: this.proceedMassDelete,
        acceptText: "Delete",
      })
    },
    proceedMassDelete(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: this.selectedIds}))
      this.resquestProcessor({formData: formData, action:'mass-delete'})
    },
    markStarted(){
      this.getSelectedRows();
      let items = this.selectedData.length
      if(items < 1){ this.alertError("You have not selected any item"); return}
      let text = `You are about to mark ${items} order${(items > 1)? 's':'' } as started`;
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: text,
        accept: this.proceedStartOrder,
        acceptText: "Accept",
      })
    },
    proceedStartOrder(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: this.selectedIds}))
      this.resquestProcessor({formData: formData, action:'mark-started'})
    },
    confirmMarkAsPaid(){
      this.getSelectedRows();
      let items = this.selectedData.length;
      if(items < 1){ this.alertError("You have not selected any item"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: `You are about to mark ${items} order${(items > 1)? 's':'' } as paid/unpaid to corresponding worker${(items > 1)? 's':'' }`,
        accept: this.proceedMarkAsPaid,
        acceptText: "Yes, Proceed",
      })
    },
    proceedMarkAsPaid(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: this.selectedIds}))
      this.resquestProcessor({formData: formData, action:'mark-as-paid'})
    },
    resquestProcessor(payload){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/orders/${payload.action}`,payload.formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.dispatch("orderList/fetchOrders")
            this.initializeAll();
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()
      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    initializeAll(){
      this.assignPopup = false;
      this.updateStatusPopup = false;
      this.campaignPopup = false
      this.selectedData = []
      this.$validator.reset()
    },
    close(){
      this.initializeAll();
    }
  },
  mounted() {
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
    this.gridApi = this.gridOptions.api
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }
    this.refreshQueryStatus()
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.vs-popup--content{
  height:100%;
}

</style>
