<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="$router.push('/orders/' + params.data.id).catch(() => {})" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
    export default {
        name: 'CellRendererActions',
        methods: {
          confirmDeleteRecord() {
            this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: 'Are you sure?',
              text: `You are about to delete order with ID: "${this.params.data.id}"`,
              accept: this.deleteRecord,
              acceptText: "Delete"
            })
          },
          deleteRecord() {
            this.$store.dispatch("orderList/removeOrder", this.params.data.id)
            .then(response => {
              if(response.data.success){
                this.alertSuccess(response.data.success)
              }else{
                this.alertError(response.data.error)
              }
            }).catch(error => { console.log(error) })
          }
        }
    }
</script>
