<template>
  <div class="flex items-center truncate h-full">
    <router-link :to="'/orders/' + params.data.id" @click.stop.prevent class="text-inherit hover:text-primary flex">
    	<vs-avatar :src="$store.state.domain+'/'+ params.data.buyer.image" class="flex-shrink-0 mr-2" size="30px" />
    	<div style="line-height: 1;" class="flex flex-col justify-center">
			  <span class="text-inherit hover:text-primary">{{ params.value }}</span>
	    </div>
    </router-link>
  </div>
</template>

<script>
    export default {
        name: 'CellRendererLink',
    }
</script>
