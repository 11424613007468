<template>
    <div class="update-status">
        <p class="text-sm" >Make sure you are confirm this operation.</p>
        <vs-divider/>
        <div class="vx-row">
            <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
                <vs-select autocomplete v-model="newStatus" class="select-large w-full">
                    <vs-select-item :key="index" :value="status.value" :text="status.text" v-for="(status, index) in statusList" />
                </vs-select>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
                <span class="h-auto">
                <vs-button class="ml-auto" @click="updateStatus()">Update</vs-button>
                </span>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    data(){
        return {
            statusList: [
                {value: 'pending',text: 'Pending'},
                {value: 'delivered',text: 'Delivered'},
                {value: 'completed',text: 'Completed'},
                {value: 'refunded',text: 'Refunded'},
                {value: 'draft', text: 'Draft'}
            ],
            newStatus: "completed",
        }
    },
    props:{
        selected:{
            type: Array,
            required: true
        }
    },
    methods:{
        updateStatus(){
            const formData = new FormData()
            formData.append('data', JSON.stringify({orders: this.selected, status: this.newStatus}))
            this.$emit("sendRequest",{formData:formData, action:'update-status'})
        },
    },
}
</script>