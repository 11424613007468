<template>
    <div class="update-status">
       <p class="text-sm" >You have selected {{selected.length}} order{{(selected.length>1)? 's':''}} to be assgined</p>
        <vs-divider/>
        <div class="vx-row">
            <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
                <span>
                <v-select label="text" multiple v-model="workers" :reduce="item => item.id" 
                    placeholder="--select worker(s)--" :closeOnSelect="true"
                    :options="workerList" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
                </span>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
                <span class="h-auto">
                <vs-button class="ml-auto" @click="assignWorker()">Assign</vs-button>
                </span>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    data(){
        return {
            workers:[],
            workerList:[],
        }
    },
    props:{
        selected:{
            type: Array,
            required: true
        }
    },
    methods:{
        assignWorker(){
            if(!this.workers.length){ this.alertError("Selected A worker"); return}
            const formData = new FormData()
            formData.append('data', JSON.stringify({orders: this.selected, workers: this.workers}))
            this.$emit("sendRequest",{formData:formData, action:'assign-workers'})
        },
        fetchWorkers(){
            if(this.workerList.length){return}
            this.$vs.loading({color: "#444", type: "sound"})
            this.$http.get("/users/workers")
            .then(response => {
                this.$vs.loading.close()
                if(response.data.success){
                    this.workerList = response.data.workers.map(emp => {
                    return { id: emp.id, text: "Worker_" + emp.id + " "+ emp.first_name + " "+ emp.last_name }})
                }
            }).catch(error => { console.log(error) })
        }
    },
    mounted(){
        this.fetchWorkers()
    }
}
</script>